/* eslint-disable camelcase */
import axios from "axios"
import router from "../router"
import { ToastProgrammatic as Toast } from 'buefy'

// import { resolve } from "core-js/fn/promise"
// import store from '../store'

const url = "/realm/user/userdata/"

export default {
    state: {
        passportUserdata: {
            surname: "",
            name: "",
            nationality: "",
            birth_date: "",
            sex: ""
        },
        passportApidata: {
            valid: "",
            fields: {
                surname: "",
                name: "",
                country: "",
                nationality: "",
                birth_date: "",
                expiry_date: "",
                sex: "",
                document_type: "",
                document_number: "",
                optional_data: "",
                birth_date_hash: "",
                expiry_date_hash: "",
                document_number_hash: "",
                optional_data_hash: "",
                final_hash: ""
            },
            image: ""
        },
        houseinfodata: {
            zipcode: "",
            number: "",
            addressId: "",
            street: "",
            city: "",
            numberaddition: "",
            houseinfo: {
                area: "",
                yearOfConstruction: "",
                status: "",
                image: ""
            }
        },
        insuranceChoice: [],
        registrationClientData: {
            emailaddress: "",
            verificationToken: "",
            password: "",
            repeatPassword: ""
        },
        cardata: {
            kenteken: ""
        },
        // registrationData: {
        //   registrationPropertyData: '',
        //   registrationClientData: ''
        // },
        numberadditionOpt: [],
        stepsDone: {
        },
        transitionName: "slideDown",
        // userProducts: '',
        // registrationStatusData: '',
        freeze: false,
        regdata: false,
        contentsCoverageOptions: "",
        homeCoverageOptions: "",
        travelCoverageChoice: "",
        travelCoverageOptions: "",
        carCoverageChoice: "",
        familyOptions: [
            { key: "alone", name: "Ik woon alleen", icon: "user" },
            { key: "withPartner", name: "Met partner", icon: "user-friends" },
            {
                key: "withPartnerAndKids",
                name: "Met partner en kind(eren)",
                icon: "users",
            },
            { key: "withKids", name: "Met kind(eren)", icon: "child" },
        ],
        petOptions: [
            { key: "dog", name: "Ja, een hond", icon: "dog" },
            { key: "cat", name: "Ja, een kat", icon: "cat" },
            { key: "dogAndCat", name: "Ja, beide!", icon: "paw" },
            { key: "none", name: "Nee, geen hond of kat", icon: "times" },
        ],
        ownerOptions: [
            { key: "rental", name: "Huurhuis", icon: "warehouse" },
            { key: "ownerOccupied", name: "Koophuis", icon: "house-user" }
        ],
        familyComposition: {family: "alone", pet: "dog", owner: "rental"}
        // allCoveragesOptions: "",
        // allCoveragesChoices: ""
    },
    getters: {
        // registrationData(state) {
        //   return state.registrationData
        // },
        stepsDone(state) {
            return state.stepsDone
        },
        passportUserdata(state) {
            return state.passportUserdata
        },
        passportApidata(state) {
            return state.passportApidata
        },
        numberadditionOpt(state) {
            return state.numberadditionOpt
        },
        houseinfodata(state) {
            return state.houseinfodata
        },
        cardata(state) {
            return state.cardata
        },
        relevantcardata(state) {
            const bouwjaardate = new Date(
                state.cardata.datum_eerste_toelating.substring(0, 4),
                state.cardata.datum_eerste_toelating.substring(4, 6) - 1,
            );
            return {
                Kenteken: state.cardata.kenteken,
                Merk: state.cardata.merk,
                Uitvoering: state.cardata.handelsbenaming,
                Voertuigsoort: state.cardata.voertuigsoort,
                Type: state.cardata.inrichting,
                Kleur: state.cardata.eerste_kleur,
                // Brandstof: state.cardata.brandstofcode,
                Bouwjaar:
                    bouwjaardate.toLocaleString("default", { month: "long" }) +
                    " " +
                    bouwjaardate.toLocaleString("default", { year: "numeric" }),
            };

        },
        relevanthouseinfodata(state) {
            return {
                Straat: state.houseinfodata.street,
                Postcode: state.houseinfodata.zipcode,
                Huisnummer: state.houseinfodata.number + " " + state.houseinfodata.numberaddition,
                Plaats: state.houseinfodata.city,
                Bouwjaar: state.houseinfodata.houseinfo.yearOfConstruction,
                Oppvervlakte: state.houseinfodata.houseinfo.area + " m2",
                Status: state.houseinfodata.houseinfo.status
            }
        },
        // registrationStatusData(state) {
        //   return state.registrationStatusData
        // },
        registrationClientData(state) {
            return state.registrationClientData
        },
        insuranceChoice(state) {
            return state.insuranceChoice
        },
        transitionName(state) {
            return state.transitionName
        },
        freeze(state) {
            return state.freeze
        },
        regdata(state) {
            return state.regdata
        },
        homeCoverageOptions(state) {
            return state.homeCoverageOptions
        },
        contentsCoverageOptions(state) {
            return state.contentsCoverageOptions
        },
        travelCoverageOptions(state) {
            return state.travelCoverageOptions
        },
        travelCoverageChoice(state) {
            return state.travelCoverageChoice
        },
        carCoverageChoice(state) {
            return state.carCoverageChoice
        },
        allCoverageOptions(state) {
            return [].concat(state.travelCoverageOptions, state.contentsCoverageOptions, state.homeCoverageOptions).filter(function (e) { return e })
        },
        allCoverageChoices(state) {
            return [].concat(state.travelCoverageChoice, state.carCoverageChoice).filter(function (e) { return e })

        },
        userProducts(state) {
            return state.userProducts
        },
        familyOptions(state) {
            return state.familyOptions
        },
        petOptions(state) {
            return state.petOptions
        },
        ownerOptions(state) {
            return state.ownerOptions
        },
        familyComposition(state) {
            return state.familyComposition

        }
    },
    mutations: {
        updatestepsDone(state, payload) {
            state.stepsDone = payload
        },
        updatepassportUserdataBE(state, payload) {
            state.passportUserdata = payload
            state.passportUserdata.birth_date =
                new Date(
                    payload.birth_date
                )
        },
        updatepassportUserdata(state, payload) {
            state.passportUserdata = payload,
                state.passportUserdata.birth_date =
                new Date(
                    parseInt("19" + payload.birth_date.substring(0, 2)),
                    payload.birth_date.substring(2, 4) - 1,
                    payload.birth_date.substring(4, 6)
                )
        },
        updatepassportApidata(state, payload) {
            state.passportApidata = payload
        },
        updatenumberadditions(state, payload) {
            state.numberadditionOpt = payload
        },
        updatehouseinfodata(state, payload) {
            state.houseinfodata = payload
        },
        updatecardata(state, payload) {
            state.cardata = payload
        },
        // saveRegistrationStatusData(state, payload) {
        //   state.registrationStatusData = payload
        // },
        clearPropertydata(state) {
            state.houseinfodata.zipcode = "",
                state.houseinfodata.number = "",
                state.houseinfodata.addressId = "",
                state.houseinfodata.street = "",
                state.houseinfodata.city = "",
                state.houseinfodata.houseinfo.area = "",
                state.houseinfodata.houseinfo.yearOfConstruction = "",
                state.houseinfodata.houseinfo.status = ""
            state.stepsDone.houseinfoApi = false
            state.stepsDone.houseinfoUser = false
            state.numberadditionOpt = []
        },
        clearPassportdata(state) {
            state.passportUserdata.surname = "",
                state.passportUserdata.name = "",
                state.passportUserdata.nationality = "",
                state.passportUserdata.birth_date = "",
                state.passportUserdata.sex = "",
                state.passportUserdata.surname = "",
                state.passportUserdata.surname = "",
                state.passportApidata.valid = "",
                state.passportApidata.fields.surname = "",
                state.passportApidata.fields.name = "",
                state.passportApidata.fields.nationality = "",
                state.passportApidata.fields.birth_date = "",
                state.passportApidata.fields.expiry_date = "",
                state.passportApidata.fields.document_type = "",
                state.passportApidata.fields.document_number = "",
                state.passportApidata.fields.optional_data = "",
                state.passportApidata.fields.birth_date_hash = "",
                state.passportApidata.fields.expiry_date_hash = "",
                state.passportApidata.fields.document_number_hash = "",
                state.passportApidata.fields.optional_data_hash = "",
                state.passportApidata.fields.final_hash = "",
                state.passportApidata.image = "",
                state.stepsDone.passportApi = false,
                state.stepsDone.passportUser = false
        },
        updateinsuranceChoice(state, payload) {
            state.insuranceChoice = payload
        },
        setTransitionName(state, payload) {
            state.transitionName = payload
        },
        updateRegData(state, payload) {
            state.regdata = payload
        },
        updatecontentsCoverageOptions(state, payload) {
            state.contentsCoverageOptions = payload
        },
        updatehomeCoverageOptions(state, payload) {
            state.homeCoverageOptions = payload
        },
        updatetravelCoverageChoice(state, payload) {
            state.travelCoverageChoice = payload
        },
        updatetravelCoverageOptions(state, payload) {
            state.travelCoverageOptions = payload
        },
        updatecarCoverageChoice(state, payload) {
            state.carCoverageChoice = payload
        },
        updatefamilyComposition(state, payload) {
            state.familyComposition = payload
        }
        // updateAllCoverages(state, payload) {
        //     state.allCoverages = payload
        // }

        // updateproducts(state, payload) {
        //   state.userProducts = payload
        // }
    },
    actions: {
        // async getPropertyData(context) {
        //   try {

        //    let response =  await axios.get(url)
        //    let responsedata = response.data.data

        //    if(response.status == 200 && responsedata.houseinfoApi)

        //    {
        //       context.commit('updatehouseinfodata',  responsedata.houseinfoApi)
        //       context.commit('updatestepsDone', response.data.stepsDone)

        //    }

        //   } catch (error) {
        //     //define specific error handlinge method here
        //   }
        // },

        async getRegistrationstatusData(context) {

            try {

                let response = await axios.get(url)
                let responsedata = response.data.data

                if (response.status == 200 && responsedata) {
                    // await context.dispatch("getProducts")

                    context.commit("updatestepsDone", response.data.stepsDone)

                    if (responsedata.houseinfoApi) {
                        context.commit("updatehouseinfodata", responsedata.houseinfoApi)
                    }

                    if (responsedata.passportApi) {
                        context.commit("updatepassportApidata", responsedata.passportApi)

                    }

                    if (responsedata.passportUser) {
                        context.commit("updatepassportUserdataBE", responsedata.passportUser)
                    }


                    if (responsedata.licenseplate) {
                        context.commit("updatecardata", responsedata.licenseplate)
                    }

                    if (responsedata.contentsCoverageOptions) {
                        context.commit("updatecontentsCoverageOptions", responsedata.contentsCoverageOptions)

                    }
                    if (responsedata.contentsCoverageOptions) {
                        context.commit("updatehomeCoverageOptions", responsedata.homeCoverageOptions)
                        // context.commit("updatedCoverageOptions", responsedata.homeCoverage, { root: true })

                    }
                    if (responsedata.travelCoverageChoice) {
                        context.commit("updatetravelCoverageChoice", responsedata.travelCoverageChoice)
                    }
                    if (responsedata.travelCoverageOptions) {
                        context.commit("updatetravelCoverageOptions", responsedata.travelCoverageOptions)
                    }
                    if (responsedata.carCoverageChoice) {
                        context.commit("updatecarCoverageChoice", responsedata.carCoverageChoice)
                    }
                    if (responsedata.familyComposition) {
                        // console.log(responsedata.familyComposition)
                        context.commit("updatefamilyComposition", responsedata.familyComposition)
                    }


                    context.commit("updateRegData", true)
                }

            } catch (error) {

                // define specific error handling actions here

            }
        },
        async updateregistration(context, { step, registrationdata, route }) {
            // console.log(registrationdata)
            // context.commit('toggleChevron', false)
            const putData = {
                data: registrationdata
            }

            const toNextRouteSteps = ["houseinfoUser", "passportUser", "facecomparison", "contentsCoverageOptions", "homeCoverageOptions", "travelCoverageChoice", "travelCoverageOptions", "carCoverageChoice"]


            if (step == "passportApi" || step == "facecomparison") {
                context.state.freeze = true
            }

            try {
                const response = await axios.put(url + step, putData)

                if (step == "houseinfoApi" && response.status == 200 && response.data.success == "partial") {

                    context.commit("updatenumberadditions", response.data.data.numberadditions)

                } else if (response.status == 200 && response.data && response.data.stepsDone) {


                    context.commit("updatestepsDone", response.data.stepsDone)


                    if (toNextRouteSteps.includes(step)) {

                        context.dispatch('toNextRoute', route)
                    }

                    if (step == "password") {
                        // context.dispatch('toNextRoute', route)
                        router.push({ path: "/preregistration/familyCompositionregistration" })
                        // go to next route

                    }

                    if (step == "houseinfoApi") {
                        context.commit("updatehouseinfodata", response.data.data)

                        Toast.open({
                            message: 'Ophalen huisgegevens gelukt!',
                            type: 'is-success'
                        })
                    }


                    if (step == "passportApi") {
                        Toast.open({
                            message: 'Inlezen passport gelukt!',
                            type: 'is-success'
                        })

                        context.commit("updatepassportApidata", response.data.data)
                        context.commit("updatepassportUserdata", response.data.data.fields)

                    }



                    if (step == "facecomparison") {
                        Toast.open({
                            message: 'Gelukt!',
                            type: 'is-success'
                        })
                    }

                    if (step == "licenseplate") {
                        Toast.open({
                            message: 'Autogegevens opgehaald!',
                            type: 'is-success'
                        })
                        context.commit("updatecardata", response.data.data)
                    }

                    if (step == "contentsCoverageOptions") {
                        context.commit("updatecontentsCoverageOptions", registrationdata.coverageOptions)
                    }
                    if (step == "homeCoverageOptions") {
                        context.commit("updatehomeCoverageOptions", registrationdata.coverageOptions)
                    }
                    if (step == "travelCoverageChoice") {
                        context.commit("updatetravelCoverageChoice", registrationdata.coverageChoice)
                    }
                    if (step == "travelCoverageOptions") {
                        context.commit("updatetravelCoverageOptions", registrationdata.coverageOptions)
                    }
                    if (step == "carCoverageChoice") {
                        context.commit("updatecarCoverageChoice", registrationdata.coverageChoice)
                    }
                    if (step == "familyComposition") {
                        context.commit("updatefamilyComposition", registrationdata)
                        router.push("/dashboard")
                    }
                }
            } catch (error) {
                console.log(error)
                if (step == "passportApi") {
                    context.state.stepsDone.passportApi = false
                    context.state.freeze = false
                }
                if (step == "facecomparison") {
                    context.state.freeze = false
                }
            }
        },

        async toFirstRoute(context) {
            const firstRoute = await context.dispatch("stepsToRoutes", context.rootState.dashboard.product.incompleteRegistrationSteps)
            router.push({ path: firstRoute[1] })
        },

        async toNextRoute(context, currentRoute) {

            context.commit("setTransitionName", "slideDown")

            const routes = context.rootState.dashboard.product.routes
            const currentIndex = routes.indexOf(currentRoute);
            const nextRoute = routes[currentIndex + 1]

            router.push({ path: nextRoute })
        },

    }
}
