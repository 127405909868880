import axios from "axios"
// import router from "../router"



export default {
    // namespaced: true,
    state: {
        metrics: null
    },
    getters: {
        metrics(state) {
            return state.metrics
        }
    },
    mutations: {


        updateMetrics(state, payload) {
            state.metrics = payload
        }

    },
    actions: {
        async getMetrics(context) {
            let metrics
            try {
               metrics = await axios.get("/metrics")
                if (metrics.status == 200 && metrics.data.data) { 
                    context.commit("updateMetrics", metrics.data.data) }
            } catch (error) {
                console.log(error)
            }
        }
    }
}
