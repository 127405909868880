<template>
  <div>
    <div id="app">
      <Loader />
      <Navbar />
      <transition
        name="component-fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>



  <script>
    import Navbar from "./components/navbar";
    import Loader from "./components/shared/loader";
    import { mapActions } from "vuex";
    import Footer from "./components/footer.vue"

    export default {
    name: "App",
    components: {
    Navbar,
    Loader,
    Footer
    },
    data() {
    return {
    paraIsVisible: true,
    };
    },
    created() {
    this.tryAutoLogin();

    },
    mounted: function () {
    },
    methods: {
    ...mapActions(["tryAutoLogin"]),

    },
    };
  </script>

  <style lang="scss">
    @import "./assets/styles/variables.scss";
    @import "./assets/styles/stylesheet.scss";

    .component-fade-enter-active,
    .component-fade-leave-active {
    transition: opacity 50ms ease;
    }
    .component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    }
  </style>
