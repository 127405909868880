import axios from "axios"
// import router from './router';
import store from "../store"
import { DialogProgrammatic as Dialog } from "buefy"

const ProdbaseURL = "https://backend.involute.nl/api/v1"
const DevbaseURL = "https://backend.involute.nl:4443/api/v1"
const LocalbaseURL = "http://localhost:8081/api/v1"

axios.interceptors.request.use(
    config => {
        if (!config.headers.noLoader) { store.commit("toggleLoader", true) }
        if (process.env.VUE_APP_NODE_ENV == "production") {
            config.baseURL = ProdbaseURL
        } else if (process.env.VUE_APP_NODE_ENV == "development") {
            config.baseURL = DevbaseURL
        } else {
            config.baseURL = LocalbaseURL
        }

        let token = store.state.general.uid
        // token = '123'
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`
        }
        return config
    },

    error => {
        store.commit("toggleLoader", false)
        if (error.response.data.message) {
            Dialog.alert({
                title: "Er gaat iets mis!",
                message: error.response.data.message,
                size: "is-small",
                type: "is-primary",
                hasIcon: true
            })
        }

        // console.log(error)
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    response => {
        store.commit("toggleLoader", false);
        return response
    },
    error => {
        store.commit("toggleLoader", false)
        if (!error.response) { return }
        else if (error.response.data.message) {
            Dialog.alert({
                title: "Er gaat iets mis!",
                message: error.response.data.message,
                size: "is-small",
                type: "is-primary",
                hasIcon: true
            })
            store.commit("updateErrormessage", error.response.data.message)
        }

        if (error.response.status == 403) {
            store.dispatch("logout")
        }
        return Promise.reject(error)
    })

