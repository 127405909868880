import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    // { path: '/:pathMatch(.*)*', redirect: '/' },
    {
        path: "",
        name: "home",
        component: () => import('../views/home'),
    },
    { path: '*', redirect: '/' },

    // { 
    //     path: '/:catchAll(.*)', 
    //     component: notFound,
    //     name: 'NotFound'
    //   },

    { path: "/login",
    name: "login", 
    component: () => import("../views/login"),
    meta: { requiresUnauth: true } },

    {
        name: "registration",
        path: "/registration", component: () => import("../views/registration"), children: [
            { path: "propertyregistration", component: () => import("../components/registration/propertyregistration"), },
            { path: "coverageOptionsregistration", component: () => import("../components/registration/coverageOptionsregistration"), },
            { path: "coverageChoiceregistration", component: () => import("../components/registration/coverageChoiceregistration"), },
            { path: "licenseplateregistration", component: () => import("../components/registration/licenseplateregistration"), },
            { path: "clientregistration", component: () => import("../components/registration/clientregistration"), },
            { path: "clientverification", component: () => import("../components/registration/clientverification"), },
            { path: "applyrequest", component: () => import("../components/registration/applyrequest"), }
        ]
    },
    {
        name: "preregistration",
        path: "/preregistration", 
        component: () => import("../views/preregistration"), children: [
            {
                path: "emailregistration", component: () => import("../components/preregistration/emailregistration"),
                // beforeEnter(to, from, next) {
                //     if (store.getters.isAuthenticated) {
                //         next("/login")
                //     } else {
                //         next()
                //     }
                // }
            },
            // { path: "transactionregistration", component: () => import("../views/preregistration/transactionregistration"), },
            { path: "familyCompositionregistration", component: () => import("../components/preregistration/familyCompositionregistration"), },
        ]
    },

    {
        path: "/example",
        name: "example",
        component: () => import("../views/example")
    },

    {
        path: "/dashboard", component: () => import("../views/dashboard"), meta: { requiresAuth: true }
        // beforeEnter(to, from, next) {
        //   console.log(store.getters.isAuthenticated)
        //   if (store.getters.isAuthenticated) {
        //         next()
        //     } else {
        //         next('/Login')
        //     }
        // }
    },
    {
        path: "/account", component: () => import("../views/account"), meta: { requiresAuth: true }
        // beforeEnter(to, from, next) {
        //   console.log(store.getters.isAuthenticated)
        //   if (store.getters.isAuthenticated) {
        //         next()
        //     } else {
        //         next('/Login')
        //     }
        // }
    },


]

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes
})

router.beforeEach(function (to, from, next) {

    if (to.meta.requiresAuth && !localStorage.getItem("uid")) {
        next("/login")
    } else if (to.meta.requiresUnauth && localStorage.getItem("uid")) {

        next("/")
    } else {
        next()
    }
})

export default router

/////////////////////////////////////////////////////VUE 3 setup////////////////////////////////////////////////////////

// import {
//   createRouter,
//   createWebHistory
// } from 'vue-router'

// import login from '../components/login.vue'
// import home from '../components/home.vue'
// import dashboard from '../components/dashboard.vue'
// import emailregistration from '../components/registrations/emailregistration.vue'
// import clientregistration from '../components/registrations/clientregistration.vue'
// import propertyregistration from '../components/registrations/propertyregistration.vue'
// import transactionregistration from '../components/registrations/transactionregistration.vue'
// import faceregistration from '../components/registrations/faceregistration.vue'
// import applyrequest from '../components/registrations/applyrequest.vue'

// import store from '../store'

// const routes = [
//   { path: '', component: home },
//   { path: '/login', component: login },
//   { path: '/emailregistration', component: emailregistration },
//   {
//         path: '/transactionregistration',
//         component: transactionregistration,
//         beforeEnter (to, from, next) {
//           if (store.getters.stepsDone.emailaddressVerification) {
//             next()
//           } else {
//             next('/')
//           }
//         }
//       },
//       {
//             path: '/propertyregistration',
//             component: propertyregistration,
//             beforeEnter (to, from, next) {
//               if (store.getters.stepsDone.insuranceChoice) {
//                 next()
//               } else {
//                 next('/')
//               }
//             }
//           },
//           {
//           path: '/clientregistration',
//           component: clientregistration,
//           beforeEnter (to, from, next) {
//             if (store.getters.stepsDone.houseinfoUser && store.getters.stepsDone.houseinfoApi) {
//               next()
//             } else {
//               next('/')
//             }
//           }
//         },
//         {
//           path: '/faceregistration',
//           component: faceregistration,
//           beforeEnter (to, from, next) {
//             if (store.getters.stepsDone.passportApi && store.getters.stepsDone.passportUser) {
//               next()
//             } else {
//               next('/')
//             }
//           }
//         },
//         {
//           path: '/applyrequest',
//           component: applyrequest,
//           beforeEnter(to, from, next) {
//             if (store.getters.stepsDone.facecomparison) {
//               next()
//             } else {
//               next('/')
//             }
//           }
//         },
//   { path: '/:pathMatch(.*)*', redirect: '/' },
//   {
//     path: '/dashboard',
//     component: dashboard,
//     beforeEnter (to, from, next) {
//       if (store.getters.isAuthenticated) {
//         next()
//       } else {
//         next('/login')
//       }
//     }
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })

// export default router

