import Vue from "vue"
import App from "./App.vue"
import Buefy from "buefy"
// import axios from 'axios'
import router from "./router"
import store from "./store"
import "./utils/vee-validate"
import "./utils/axiosinterceptors"
import ScrollAnimation from "./directives/scrollanimation"

import 'chart.js'
import 'vue-chartjs'

Vue.directive('scrollanimation', ScrollAnimation);
Vue.config.productionTip = false
Vue.use(Buefy, {
    defaultIconPack: "fas"
    // defaultFieldLabelPosition: 'on-border'
})





new Vue({
    el: "#app",
    store,
    router,
    render: h => h(App)
})

