import Vue from "vue"
import Vuex from "vuex"

import transactions from "./transactions"
import registration from "./registration"
import dashboard from "./dashboard"
import userdata from "./userdata"
import general from "./general"
import image from "./image"
import metrics from "./metrics"



Vue.use(Vuex)

const store = new Vuex.Store({

    modules: {
        general,
        transactions,
        registration,
        dashboard,
        userdata,
        image,
        metrics
    }

})

export default store
